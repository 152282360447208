/* eslint-disable react-hooks/exhaustive-deps */
import { CCard, CCardBody, CCardHeader, CDataTable, CRow } from "@coreui/react";
import  { useContext, useEffect, useMemo, useState } from "react";
import { useLocation,useHistory } from "react-router-dom";
import { getTokenFromLocalStorage, getZoneFromLocalStorage } from "../../../../Services/LocalStorageService";
import { v4 as uuidv4 } from 'uuid';
import { LoggerContext } from "../../../WMS/Common_Helper/services/uiLoginServices";
import AButton from "../../../../Components/AButton";
import DateCell from "./Scopedslotcell";
import createInterceptorInstance from "../../../../Services/Interceptor";
import { HandleMessageFromResponse } from "../../../../helpers/handleErrorMessage";
import { Helmet } from "react-helmet";

export const JobHistory = () => {
    const { logger } = useContext(LoggerContext);
    const location = useLocation();
    const history = useHistory();
    const [runHistory, setRunHistory] = useState([]);
    const [tableData, setTabledata] = useState(true);
    const [exceptionMessage, setExceptionMessage] = useState(null);

    useEffect(() => {
        const guid = uuidv4();
        let zone = getZoneFromLocalStorage(); let baseURL="";
        
        if(zone === "East"){
            baseURL = process.env.REACT_APP_AQUASCHEDULER_URL_EAST
        }else{
            baseURL = process.env.REACT_APP_AQUASCHEDULER_URL_WEST
        }
        const URL = createInterceptorInstance(`${baseURL}/AquaScheduler/JobHistory`);
        URL.get('', { headers: { "Authorization": `Bearer ${getTokenFromLocalStorage()}`, "jobId": location?.jobId || localStorage.getItem("jobId") } })
        .then((res) => {
            if (res.status === 200) {
                setRunHistory(res.data.data);
                setTabledata(false);
            }else{
                setTabledata(false);
            }
            logger('debug',guid, res, "AIMS360RunwayAquaScheduler", `${baseURL}/AquaScheduler/JobHistory`,"GET");
        }).catch((ex)=>{
            logger('error',guid, ex, "AIMS360RunwayAquaScheduler", `${baseURL}/AquaScheduler/JobHistory`,"GET");
            console.log(ex);
            setTabledata(false);
            handleError(ex);
        })
    }, [])

    const handleError = (err) => {
        setExceptionMessage(err);
    }
    const fields = [
        { key: "backgroundJobRunHistoryID", label: "History Id", _style: { maxWidth: "10%" } },
        { key: 'jobStatus', label: "Job Status", _style: { maxWidth: "10%" } },
        { key: 'accountCode', label: "Account Code", _style: { maxWidth: "10%" } },
        { key: 'databaseName', label: "Database name", _style: { maxWidth: "10%" } },
        { key: 'response', label: "Response Type", _style: { maxWidth: "20%" } },
        { key: 'createdBy', label: "Created By", _style: { maxWidth: "10%" } },
        { key: 'startDate', label: "Start Date", _style: { maxWidth: "10%" } },
        { key: 'completedDate', label: "Completed Date", _style: { maxWidth: "10%" } },
        { key: 'purgeDateTime', label: "Purge Date", _style: { maxWidth: "10%" } }
    ]

    const startDateMemo = useMemo(()=>{
        return (item) =>(
            <DateCell date={item?.startDate} />
        )
    },[])

    const completedDateMemo = useMemo(()=>{
        return (item) =>(
            <DateCell date={item?.completedDate} />
        )
    },[])

    const purgeDateMemo = useMemo(()=>{
        return (item) =>(
            <DateCell date={item?.purgeDateTime} />
        )
    },[])

    return (
        <>
         <Helmet>
                <title>AIMS360 - Reports AquaScheduler JobList History</title>
            </Helmet>
            <CCard  className={location?.pathname === "/aquascheduler/Jobhistory"? "vh-100 mb-0":"mb-0"}>
                <CCardHeader>
                    <div className="d-flex justify-content-between">
                        <p>Job Run History</p>
                        {
                            location?.pathname ==="/aquascheduler/Jobhistory" &&
                        <AButton color="primary" onClick={()=>{history.push({pathname:"/aquascheduler/joblist",jobList:location?.jobList})}} text="Back" />
                        }
                    </div>
                </CCardHeader>
                {
                exceptionMessage ?
                        <div className="mt-2 row justify-content-center">
                           
                            <div className="col-md-6 col-sm-12">
                                <div className="mx-2">
                                    <HandleMessageFromResponse data={exceptionMessage}/>
                                </div>
                            </div>
                          
                        </div> : <CCardBody style={{overflowY :"scroll"}}>
                    {
                        tableData ?
                            <CRow className="justify-content-center">
                                <div><i className="fas fa-spinner fa-spin fa-2x"></i></div>
                            </CRow> :
                            <CDataTable
                                items={runHistory}
                                fields={fields}
                                sorter
                                tableFilter={{ label: "Search:", placeholder: "search" }}
                                itemsPerPageSelect={{ label: "Show:" }}
                                itemsPerPage={5}
                                hover
                                pagination
                                scopedSlots={{
                                    startDate:startDateMemo,
                                    completedDate:completedDateMemo,
                                    purgeDateTime:purgeDateMemo

                                }}
                            />
                    }
                </CCardBody>
            }
            </CCard>            
        </>
    )
}
