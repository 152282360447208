/* eslint-disable react-hooks/exhaustive-deps */
import { CButton, CCard, CCardBody, CCardHeader, CCol, CInput, CLabel, CLink, CRow, CTooltip } from "@coreui/react";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom"
import AButton from "../../../../Components/AButton";
import { getTokenFromLocalStorage, getUserDetailsFromLocalStorage, getZoneFromLocalStorage } from "../../../../Services/LocalStorageService";
import { capitalizeFirstCharacter, createPayloadEditor, customMessage } from "../../../reportMail/reportHelper/customMessage";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { LoggerContext } from "../../../WMS/Common_Helper/services/uiLoginServices";
import { Helmet } from "react-helmet";
import { HandleMessageFromResponse } from "../../../../helpers/handleErrorMessage";
import createInterceptorInstance from "../../../../Services/Interceptor";
import { getUserAccessPermission, readPermissionMessage } from "../../../../Services/UserAccessPermission";

export const EditDetails = () => {
    const { logger } = useContext(LoggerContext);
    const history = useHistory();
    const location = useLocation();
    const [modalSchedulerBody] = useState({})
    const Data = localStorage.getItem("payloadSetting");
    const localdata = Data ? JSON.parse(Data) : [];
    const origin = _.cloneDeep(location?.origin ? location?.origin : localdata);

    const userDetails = getUserDetailsFromLocalStorage();
    const clientAccount = userDetails?.LoginAccountCode;
    const [modalBody, setModalBody] = useState(location?.modalData ? location?.modalData : localdata);
    const [schedulerText, setScheduleText] = useState("");
    const [spinner, setSpinner] = useState({ update: false, delete: false })
    const [isEnable, setIsEnable] = useState(false);
    const [exceptionMessage, setExceptionMessage] = useState(null);
    const [isUserHasOnlyReadAccess,setIsUserHasOnlyReadAccess]=useState(true);

    useEffect(() => {
        if (location?.state) {
            let Editedsetttings = {};
            Editedsetttings.frequency = location?.state?.frequency;
            Editedsetttings.interval = location?.state?.interval;
            Editedsetttings.token = location?.state?.token;
            if (location?.state?.frequency) {
                Editedsetttings[location.state.frequency] = {
                    days : location?.state?.days,
                    hours: location?.state?.hours,
                    minutes: location?.state?.minutes
                };
            }
            if (location?.state?.date) {
                Editedsetttings.date = location?.state?.date;
            }


            setModalBody({ ...modalBody, ...location?.modalData, recurrence: Editedsetttings })
        }
        let isAccess=getUserAccessPermission("AIMS360OTSAJ01");
        setIsUserHasOnlyReadAccess(isAccess);
    }, [])

    useEffect(() => {
        createSchedulerBody();
        setScheduleText(customMessage(modalSchedulerBody));
        let oroginData = createPayloadEditor(origin)
        
        if (_.isEqual(modalSchedulerBody, oroginData)) {
            setIsEnable(true);
        } else {
            setIsEnable(false);
        }
    }, [modalBody])

    const createSchedulerBody = () => {
        if (modalBody?.length !== 0) {
            modalSchedulerBody.frequency = capitalizeFirstCharacter(modalBody?.recurrence?.frequency);
            modalSchedulerBody.interval = modalBody?.recurrence?.interval;
            modalSchedulerBody.token = getTokenFromLocalStorage();
            modalSchedulerBody.scheduledJobName = modalBody?.scheduledJobName;
            modalSchedulerBody.description = modalBody?.description;
            modalSchedulerBody.SchedulerJobId = modalBody?.scheduledJobID;
            modalSchedulerBody.date = location?.state?.date ? location?.state?.date : new Date(modalBody?.startDateTime).getDate().toString();
            modalSchedulerBody.SchedulerJobGuid = modalBody?.pubLink.split('/')[6];
            if (modalBody?.recurrence?.frequency) {
                modalSchedulerBody.days = modalBody?.recurrence[`${modalBody?.recurrence?.frequency}`]?.days;
                modalSchedulerBody.hours = modalBody?.recurrence[`${modalBody?.recurrence?.frequency}`]?.hours;
                modalSchedulerBody.minutes = modalBody?.recurrence[`${modalBody?.recurrence?.frequency}`]?.minutes;
            }
        }
    }

   

    const UpdateJob = () => {
        const guid = uuidv4();
        setSpinner({ ...spinner, update: true });
        setIsEnable(true);
        createSchedulerBody();
        let zone = getZoneFromLocalStorage(); let url = "";

        if (zone === "East") {
            url = process.env.REACT_APP_AQUASCHEDULER_URL_EAST
        } else {
            url = process.env.REACT_APP_AQUASCHEDULER_URL_WEST
        }
        const URL = createInterceptorInstance(`${url}/AquaScheduler/UpdateJob`);
        URL.post('', modalSchedulerBody, { headers: { "Authorization": `Bearer ${getTokenFromLocalStorage()}` } }).then((res) => {
          if(res.status === 200){
            setSpinner({ ...spinner, update: false });
            localStorage.removeItem("payloadSetting");
            logger('debug',guid, res, "AIMS360RunwayAquaScheduler", `${url}/AquaScheduler/UpdateJob`,"POST",modalSchedulerBody);
            history.push({ pathname:location?.pathname === "/reports/aquascheduler/joblist/Edit" ? '/reports/aquascheduler/joblist' : `/aquascheduler/joblist`, search: `?loginprefix=${clientAccount}`, state: location?.settings, modalData: location?.modalData })
          }else{
            setIsEnable(false);
          }
        }).catch((ex) => {
            logger('error',guid, ex, "AIMS360RunwayAquaScheduler", `${url}/AquaScheduler/UpdateJob`,"POST",modalSchedulerBody);
            console.log(ex);
            setSpinner({ ...spinner, update: false });
            handleError(ex);
        })
    }

    const handleError = (err) => {
        setExceptionMessage(err);
    }

    const DeleteJob = () => {
        const guid = uuidv4();
        setSpinner({ ...spinner, delete: true });
        let zone = getZoneFromLocalStorage(); let url = "";

        if (zone === "East") {
            url = process.env.REACT_APP_AQUASCHEDULER_URL_EAST
        } else {
            url = process.env.REACT_APP_AQUASCHEDULER_URL_WEST
        }
        const URL = createInterceptorInstance(`${url}/AquaScheduler/DeleteJob`);
        URL.delete('', { headers: { "Authorization": `Bearer ${getTokenFromLocalStorage()}`, "JobId": modalBody.scheduledJobID } })
            .then((res) => {
                setSpinner({ ...spinner, delete: false });
                localStorage.removeItem("payloadSetting");
                logger('debug',guid, res, "AIMS360RunwayAquaScheduler", `${url}/AquaScheduler/DeleteJob`,"DELETE");
                history.push({ pathname: location?.pathname === "/reports/aquascheduler/joblist/Edit" ? '/reports/aquascheduler/joblist' : `/aquascheduler/joblist`, search: `?loginprefix=${clientAccount}`, state: location?.settings, modalData: location?.modalData })
            }).catch((ex) => {
                logger('error',guid, ex, "AIMS360RunwayAquaScheduler", `${url}/AquaScheduler/DeleteJob`,"DELETE");
                console.log(ex);
                setSpinner({ ...spinner, delete: false });
                handleError(ex);
            })
    }


    return (
        <>
            <Helmet>
                <title>AIMS360 - Reports AquaScheduler Edit Details</title>
            </Helmet>
            <CCard className={location?.pathname === "/aquascheduler/editjob" ? "vh-100 mb-0" : "mb-0"}>
                <CCardHeader>
                    <div className="d-flex justify-content-between">
                        <p>Edit Details</p>
                        {
                            location?.pathname === "/aquascheduler/editjob" &&
                            <AButton color="primary" onClick={() => { history.push({ pathname: "/aquascheduler/joblist", search: `?loginprefix=${clientAccount}`, jobList: location?.jobList }) }} text="Back"/>
                        }
                    </div>
                </CCardHeader>
                <CCardBody>
                    <CRow className="justify-content-center">
                        <CCol md="6">
                            <div className="row">
                                <CCol md="4">
                                    <CLabel htmlFor="Settings"><span className="text-danger">*</span>Job Name</CLabel>
                                </CCol>
                                <CCol md="8" className="mb-3">
                                    <CInput type="text" name="jobName" disabled autoComplete="off" value={modalBody?.scheduledJobName} onChange={(e) => { setModalBody({ ...modalBody, scheduledJobName: e.target.value }) }} placeholder="Enter The Job Name" className="" />
                                </CCol>
                                <CCol md="4" className="">
                                    <CLabel htmlFor="Settings">Job Description</CLabel>
                                </CCol>
                                <CCol md="8" className="mb-2">
                                    <textarea type="text" name="jobDesc" value={modalBody?.description} onChange={(e) => { setModalBody({ ...modalBody, description: e.target.value.length > 8000 ? e.target.value.slice(0,8000):e.target.value }) }} placeholder="Enter The Job Description" className="form-control" ></textarea>
                                </CCol>
                            </div>
                            <CCard className="mb-2">
                                <CCardBody className="px-2">
                                    <CRow>
                                        <CCol md="4" className="">
                                            <CLabel htmlFor="Settings"><span className="text-danger">*</span>Scheduler Settings</CLabel>
                                        </CCol>
                                        <CCol md="8">
                                            <CInput type="text" name="Settings" placeholder="Show the scheduler" value={schedulerText} className="" disabled />
                                        </CCol>
                                    </CRow>
                                    <div className="d-flex justify-content-end mt-2">
                                        {
                                            isUserHasOnlyReadAccess ? (<div className="text-right ">
                                            <CTooltip content={readPermissionMessage} placement="top">
                                            <CLink>
                                            <AButton color="primary"
                                                onClick={() => {
                                                    history.push({
                                                        pathname: location.pathname === "/reports/aquascheduler/joblist/Edit" ? '/reports/aquascheduler/joblist/scheduler' : `/aquascheduler/SchedulerEditor`,
                                                        search: `?loginprefix=${clientAccount}`,
                                                        source: location?.pathname,
                                                        settings: modalSchedulerBody,
                                                        origin:origin,
                                                        modalData: modalBody
                                                    })
                                                }}
                                                disabled={true}
                                                style={{cursor:"not-allowed"}}
                                                text="Edit"
                                            />
                                            </CLink>
                                            </CTooltip>
                                        </div>):
                                        (<div className="text-right ">
                                            <AButton color="primary"
                                                onClick={() => {
                                                    history.push({
                                                        pathname: location.pathname === "/reports/aquascheduler/joblist/Edit" ? '/reports/aquascheduler/joblist/scheduler' : `/aquascheduler/SchedulerEditor`,
                                                        search: `?loginprefix=${clientAccount}`,
                                                        source: location?.pathname,
                                                        settings: modalSchedulerBody,
                                                        origin:origin,
                                                        modalData: modalBody
                                                    })
                                                }}
                                                text="Edit"
                                            />
                                        </div>)
                                        }
                                    </div>
                                </CCardBody>
                            </CCard>
                            <div className="row justify-content-end">
                                <CCol md="5">
                                    {
                                    isUserHasOnlyReadAccess ?
                                    <div className="d-flex float-right">
                                        
                                            <CTooltip content={readPermissionMessage} placement="top">
                                            <CLink className="d-flex">
                                                <AButton className="btn-danger mr-1" isLoading={spinner.delete} disabled={true} style={{cursor:"not-allowed"}} text="Delete Job" onClick={() => { DeleteJob() }} />
                                                <CButton className="btn-primary" disabled={true} style={{cursor:"not-allowed"}} onClick={() => { UpdateJob() }}>Update Job {spinner.update && <i className="fas fa-spinner fa-spin"></i>}</CButton>      
                                            </CLink>
                                            </CTooltip>
                                    </div>:
                                    <div className="d-flex float-right">
                                        <AButton className="btn-danger mr-1" isLoading={spinner.delete} text="Delete Job" onClick={() => { DeleteJob() }} />
                                        <CButton className="btn-primary" disabled={isEnable} onClick={() => { UpdateJob() }}>Update Job {spinner.update && <i className="fas fa-spinner fa-spin"></i>}</CButton>     
                                    </div>
                                    }
                                </CCol>
                            </div>
                            {
                                exceptionMessage ?
                                <div className="mt-2">
                                    <HandleMessageFromResponse data={exceptionMessage}/>
                                </div> : ""
                            }
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </>
    )
}
