import React from 'react';

const JobIdCell = ({ uri }) => {
  const jobId = uri?.split("backgroundjob/")[1]?.split("/")[0];
  return (
    <td>
      <p>{jobId}</p>
    </td>
  );
};

export default JobIdCell;