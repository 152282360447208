/* eslint-disable react-hooks/exhaustive-deps */
import { CCard, CCardBody, CCardHeader, CDataTable, CRow } from "@coreui/react";
import React, { useContext, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { getTokenFromLocalStorage, getUserDetailsFromLocalStorage, getZoneFromLocalStorage } from "../../../../Services/LocalStorageService";
import { Authenticate } from "../../index";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import { LoggerContext } from "../../../WMS/Common_Helper/services/uiLoginServices";
import JobIdCell from "../JobIdCell";
import Action from "./Actions";
import PubLinkCell from "./PublicLinkCell";
import createInterceptorInstance from "../../../../Services/Interceptor";
import { HandleMessageFromResponse } from "../../../../helpers/handleErrorMessage";
import "../../../WMS/wms.scss";

export const JobList = () => {
    const { logger } = useContext(LoggerContext);
    const [job, setJob] = useState([])
    const [exceptionMessage, setExceptionMessage] = useState(null);
    const [modalBody, setModalBody] = useState([]);
    const [table, setTable] = useState(false);
    const location = useLocation();
    const params = new URLSearchParams(window?.location?.href);
    const userDetails = getUserDetailsFromLocalStorage();
    const clientAccount = userDetails?.LoginAccountCode;
    const [modalSchedulerBody] = useState({});

    React.useEffect(() => {
        location?.pathname === "/aquascheduler/joblist" && userDetails?.UserToken === undefined && Authenticate();
        if (location?.jobList) {
            setModalBody({ ...modalBody, ...location?.modalData })
            setJob(location?.jobList);
            setTable(true)
        } else {
            getJobList();
        }
    }, [])

    const getJobList = () => {
        const guid = uuidv4();
        let zone = params.get("zone") ? params.get("zone") : getZoneFromLocalStorage();
        let baseURL = "";
        let tokenfromstorage = getTokenFromLocalStorage();
        let tokenfromURL = params.get("token");

        if (zone === "East") {
            baseURL = process.env.REACT_APP_AQUASCHEDULER_URL_EAST
        } else {
            baseURL = process.env.REACT_APP_AQUASCHEDULER_URL_WEST
        }
        const URL = createInterceptorInstance(`${baseURL}/AquaScheduler/JobList`);
        URL.get('', { headers: { "Authorization": `Bearer ${tokenfromURL || tokenfromstorage}` } }).then((res) => {
            if (res.status === 200) {
                let sortedJobs = _.orderBy(res.data.data, (o) => o?.createdDateTime, ['desc'])
                setJob(sortedJobs);
                setTable(true);
            }
            logger('debug', guid, res, "AIMS360RunwayAquaScheduler", `${baseURL}/AquaScheduler/JobList`, "GET");
        }).catch((ex) => {
            logger('error', guid, ex, "AIMS360RunwayAquaScheduler", `${baseURL}/AquaScheduler/JobList`, "GET");
            handleError(ex);
            setTable(true);
        })
    }

    const handleError = (error) => {
        setExceptionMessage(error);
    }

    const fields = [
        { key: "createdBy", label: "Created By" },
        { key: 'id', label: "Job Id", _style: { width: "20%" } },
        { key: 'scheduledJobName', label: "Job Name" },
        { key: 'status', label: "Job Status" },
        { key: 'description', label: "Job Description" },
        { key: 'pubLink', label: "Publishing URL" },
        { key: 'Action', label: "Action", sorter: false, filter: false }
    ]

    const idCellMemo = useMemo(() => {
        return (item) => (
            <JobIdCell uri={item?.scheduledJobAction?.uri} />
        )
    }, [])

    const actionsMemo = useMemo(() => {
        return (item) => (
            <Action
                item={item}
                clientAccount={clientAccount}
                modalSchedulerBody={modalSchedulerBody}
                job={job}
            />
        )
    }, [])

    const publicLinkCellMemo = useMemo(() => {
        return (item) => (
            <PubLinkCell item={item} />
        )
    }, [])

    return (
        <>
            <Helmet>
                <title>AIMS360 - Reports AquaScheduler JobList</title>
            </Helmet>
            <div>
                <CCard className={location?.pathname === "/aquascheduler/joblist" ? "vh-100 mb-0" : ""}>
                    <CCardHeader>
                        <p>Aqua Job List</p>
                    </CCardHeader>
                    {
                        exceptionMessage ?
                            <div className="mt-2 row justify-content-center">
                                <div className="col-md-6 col-sm-12">
                                    <div className='mx-2'>
                                        <HandleMessageFromResponse data={exceptionMessage} />
                                    </div>
                                </div>
                            </div> :
                            <CCardBody className="setselect" style={{ overflowY: "scroll" }}>
                                {
                                    !table ?
                                        <CRow className="justify-content-center">
                                            <div className="col-6 justify-content-center"><i className="fas fa-spinner fa-spin fa-2x"></i></div>
                                        </CRow> :
                                        <CDataTable
                                            items={job}
                                            fields={fields}
                                            tableFilter={{ label: "Search:", placeholder: "search" }}
                                            itemsPerPageSelect={{ label: "Show:" }}
                                            itemsPerPage={5}
                                            hover
                                            sorter
                                            pagination
                                            scopedSlots={{
                                                Action: actionsMemo,
                                                id: idCellMemo,
                                                pubLink: publicLinkCellMemo
                                            }}
                                        />
                                }
                            </CCardBody>
                    }
                </CCard>
            </div>
        </>
    )
}
